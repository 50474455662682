@charset "utf-8";

h1, h2, h3, h4, h5, h6 {
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    list-style-position: initial;
    list-style-image: initial;
}
.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
	font-size: inherit;
}
.shadow-demo {
    background-color: #26a69a;
    width: 100px;
    height: 100px;
    margin: 20px auto;
}
h1.page-title {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    color: #29b6f6;
}
.col.grid-example {
    border: 1px solid #eee;
    margin: 7px 0;
    text-align: center;
    line-height: 50px;
    font-size: 28px;
    background-color: tomato;
    color: white;
    padding: 0;
}
.collection .collection-item.avatar {
    padding-right: 30px;
}
.last-post {
    padding: 10px 0 15px;
}
.last-post .collection .collection-item.avatar>.date-post {
    position: absolute;
    width: 54px;
    height: 54px;
    overflow: hidden;
    left: 10px;
    display: inline-block;
    padding: 16px 0;
    vertical-align: middle;
    background: #ad1457;
    color: #fff;
    text-align: center;
}
.txt-center {
    text-align: center;
}
.post-header h1.post-title {
    color: #29b6f6;
    font-size: 2.8em;
    font-weight: 800;
}
.post-header time {
    color: #cacaca;
    font-size: 0.9em;
}
p.rss-subscribe {
    margin: 0;
}
